import { environment } from 'environments/environment';
import { getDefaultConfig, getUserId } from './common';
import { fetchJSON, postFORM, postJSON, putJSON, getFORM } from './fetch.js';
// @ts-ignore
import $ from 'jquery';

const userId = getUserId();
export type PortfolioList = {
  strategyType: string;
  fetchDetail: boolean;
  status: string;
  portfolioClass: string;
};

export type StrategyList = {
  status: string;
  fetchDetail: boolean;
  fetchTrackingReport: boolean;
  openForInvestor: boolean;
  strategyType: string;
};

export type AqmStrategy = {
  status: string | null;
  aqmId: string | null;
  trackAccountId: string | null;
  name: string | null;
  note: string | null;
};

export type CompareStrategy = {
  status: string;
  fetchDetail: boolean;
  fetchTrackingReport: boolean;
  openForInvestor: boolean;
  strategyType: string;
  name: string;
};

export const getPortfolioList = (params: PortfolioList) => {
  let url = environment.api_url + 'portfolios?userId=';
  url += `${userId}&strategyType=${params.strategyType}&fetchDetail=${params.fetchDetail}&status=${params.status}&portfolioClass=${params.portfolioClass}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getSvg = (portfolioId: number, name: string, subPath: string) => {
  const url = `${environment.perf_image_url}images/${subPath}/${portfolioId}${name}`;
  return url;
};

export const getSvgWork = (portfolioId: number, name: string) => {
  return (
    environment.perf_image_url +
    'images/' +
    (Math.abs(portfolioId) % 255) +
    '/' +
    portfolioId +
    name
  );
};

export const getStrategyList = (params: StrategyList) => {
  let url =
    environment.api_url +
    `strategies?status=${params.status}&fetchDetail=${params.fetchDetail}&fetchTrackingReport=${params.fetchTrackingReport}`;
  url += `&openForInvestor=${params.openForInvestor}&strategyType=${params.strategyType}&marketType=US&__pageno__=1&__pagesize__=50`;
  console.debug('getStrategyList url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

export const getCompareStrategy = (params: CompareStrategy) => {
  let url =
    environment.api_url +
    `strategies?status=${params.status}&fetchDetail=${params.fetchDetail}&fetchTrackingReport=${params.fetchTrackingReport}`;
  url += `&openForInvestor=${params.openForInvestor}&strategyType=${params.strategyType}&name=${params.name}`;
  console.debug('getStrategyList url = %s encodeURI = %s', url, encodeURI(url));
  return fetchJSON(encodeURI(url), getDefaultConfig(null));
};

export const getTrackAccount = () => {
  //api/users/trackAccount
  let url = `${environment.api_url}users/trackAccount?isDeleted=false`;
  //console.debug('getTrackAccount url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

// export const subscribePortfolio11 = (params) => {
//   const postParams = $.param(params);
//   let url = `${environment.api_url}portfolios/buildCorePortfolio`;
//   return postJSON(url, postParams);
// };

export const subscribePortfolio = (portfolio) => {
  const postParams = $.param(portfolio);
  // console.debug('subscribePortfolio postParams = %s', postParams);
  let url = `${environment.api_url}portfolios/buildCorePortfolio`;
  return postFORM(url, postParams, getDefaultConfig(null));
};

export const startSubscribePortfolio = (id) => {
  let url = `${environment.api_url}portfolios/start?portfolioId=${id}`;
  console.debug('startSubscribePortfolio url = %s', url);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getStrategy = (id, fetchDetail) => {
  console.debug('startSubscribePortfolio id = %s', id);
  // GET
  let url = `${environment.api_url}strategies?id=${id}`;
  if (fetchDetail) {
    url += '&fetchDetail=true';
  }
  return fetchJSON(url, getDefaultConfig(null));
};

export const canStrategyBackTest = (id) => {
  console.debug('canStrategyBackTest id = %s', id);
  // GET
  let url = `${environment.api_url}strategies/canBacktest?strategyId=${id}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const strategyBackTestDateRange = (marketType = 'US') => {
  console.debug('strategyBackTestDateRange marketType = %s', marketType);
  let url = `${environment.api_url}strategies/backtestDateRange?marketType=${marketType}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getStrategyTrackingInfo = (strategyId) => {
  console.debug('strategyBackTestDateRange strategyId = %s', strategyId);
  let url = `${environment.api_url}strategies/trackingReport?strategyId=${strategyId}&__sort__=tradeDate&__pagesize__=10000`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getStrategyBacktestInfo = (portfolioId) => {
  console.debug('getStrategyBacktestInfo portfolioId = %s', portfolioId);
  let url = `${environment.api_url}strategies/backtestReportPerformance?portfolioId=${portfolioId}&__pageno__=1&__pagesize__=10000`;
  return fetchJSON(url, getDefaultConfig(null));
};

// /svr/user/portfolios/saveAQM：保存AQM
// /svr/user/portfolios/activateAQM：激活某个AQM

// /svr/user/portfolios/findAQM：查找AQM find all aqm, GET, status: 状态,可用值:Active,Inactive,Stop
// /svr/user/portfolios/subscribeAQM：订阅某个AQM subscribe a aqm   PUT, aqmId, trackAccountId
// /svr/user/portfolios/findAQMSubscribe：查找订阅的AQM find my aqm  GET, status: Subscribing,Subscribed,Fail,Stop
// /svr/user/portfolios/stopSubscribeAQM：停止订阅某个AQM stop a aqm PUT, subscribeId

export const getAQMStrategyList = () => {
  let url = environment.api_url + `portfolios/findAQM?status=Active&__pageno__=1&__pagesize__=50`;
  console.debug('getAQMStrategyList url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

export const getMyAQMStrategyList = (params: AqmStrategy) => {
  // status: Subscribing,Subscribed,Fail,Stop
  let url = `${environment.api_url}portfolios/findAQMSubscribe?__pageno__=1&__pagesize__=50`;
  if (params.status) {
    url += `&status=${params.status}`;
  }
  if (params.id) {
    url += `&id=${params.id}`;
  }
  console.debug('getMyAQMStrategyList url = %s', url);
  return fetchJSON(url, getDefaultConfig(null));
};

export const subscribeAQMStrategy = (params: AqmStrategy) => {
  let url =
    environment.api_url +
    `portfolios/subscribeAQM?aqmId=${params.aqmId}&trackAccountId=${params.trackAccountId}&name=${params.name}&note=${params.note}`;
  console.debug('subscribeAQMStrategy url = %s', url);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const stopAQMStrategy = (params: AqmStrategy) => {
  let url = environment.api_url + `portfolios/stopSubscribeAQM?subscribeId=${params.aqmId}`;
  console.debug('stopAQMStrategy url = %s', url);
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getAQMPosition = (params: AqmStrategy) => {
  let url = environment.api_url + `portfolios/getAQMPositions?aqmSubscribeId=${params.id}`;
  //let url = `http://localhost:8080/svr/user/portfolios/getAQMPositions?aqmSubscribeId=${params.id}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getStrategySnapshop = (id) => {
  let url = `${environment.api_url}portfolios/findStrategyPositions?strategyId=${id}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const findSymbol = (symbol) => {
  // let url = `http://localhost:8080/svr/user/ida/validateSymbol?symbol=${symbol}`;
  let url = `${environment.api_url}ida/validateSymbol?symbol=${symbol}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const saveSymbols = (symbol) => {
  let url = `${environment.api_url}ida/saveSymbols?symbols=${symbol}`;
  // let url = `http://localhost:8080/svr/user/ida/saveSymbols?symbols=${symbol}`;
  return putJSON(url, {}, getDefaultConfig(null));
};

export const getSymbols = () => {
  let url = `${environment.api_url}ida/getSymbols`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getWatchList = () => {
  let url = `${environment.api_url}ida/getIDAWatchList`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const saveWatchList = (params) => {
  const postParams = $.param(params);
  let url = `${environment.api_url}ida/saveIDAWatchList`;
  return postFORM(url, postParams, getDefaultConfig(null));
};

export const deleteWatchList = (id) => {
  const postParams = $.param({"id": id});
  let url = `${environment.api_url}ida/deleteIDAWatchList`;
  return postFORM(url, postParams, getDefaultConfig(null));
};

export const getStockIDA = (symbol, func, period, length, dim, group, accountid) => {
  let url = `${environment.api_url}ida/getStockIDA?function=${func}&length=${length}`;
  // let url = `http://localhost:8080/svr/user/ida/getStockIDA?function=${func}&length=${length}`;
  if (symbol) {
    url += `&symbol=${symbol}`;
  }
  if (period) {
    url += `&period=${period}`;
  }
  if (dim) {
    url += `&dim=${dim}`;
  }
  if (group) {
    url += `&group=${group}`;
  }
  if (accountid) {
    url += `&accountid=${accountid}`;
  }
  // return fetchJSON(url,  getDefaultConfig(null), {timeout: 20000});
  return fetchJSON(url,  getDefaultConfig(null));
};

export const getEtfIDA = (group) => {
  let url = `${environment.api_url}ida/getEtfIDA?group=${group}`;
  return fetchJSON(url, getDefaultConfig(null));
};

export const getAQMModelAccountPositions = (id) => {
  let url = environment.api_url + `portfolios/getAQMModelAccountPositions?aqmId=${id}`;
  return fetchJSON(url, getDefaultConfig(null));
};
